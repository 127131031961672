import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Box = makeShortcode("Box");
const Tout = makeShortcode("Tout");
const Icon = makeShortcode("Icon");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`What is a DAO?`}</h1>
    <Box sx={{
      padding: 4
    }} mdxType="Box">
      <h2>{`Learn about Decentralized Autonomous Organizations`}</h2>
      <p>{`A decentralized autonomous organization, or DAO, is a kind of organizational structure that prioritizes decentralization for governance. `}</p>
      <p>{`DAOs rely on their stakeholders to maintain and advance the project. In the context of MakerDAO, its community manages the Maker Protocol through an embedded governance mechanism.`}</p>
      <p>{`It is `}<strong parentName="p">{`decentralized`}</strong>{` in that no single party has full control over the system and `}<strong parentName="p">{`autonomous`}</strong>{` in that once deployed, the Maker Protocol facilitates governance through the built-in governance mechanism and the existence of MKR.`}</p>
    </Box>
    <Tout mdxType="Tout">
      <Box mdxType="Box">
        <h2>{`MakerDAO `}<Icon name="maker_circle_color" size={30} mdxType="Icon" /></h2>
        <p>{`Learn about MakerDAO and how it operates.`}</p>
        <p><a parentName="p" {...{
            "href": "/learn/MakerDAO"
          }}>{`MakerDAO`}</a></p>
      </Box>
      <Box mdxType="Box">
        <h2>{`Dai `}<Icon name="dai_circle_color" size={30} mdxType="Icon" /></h2>
        <p>{`Learn about Dai.`}</p>
        <p><a parentName="p" {...{
            "href": "/learn/Dai"
          }}>{`Dai`}</a></p>
      </Box>
    </Tout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      